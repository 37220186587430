import React from "react";
import TextSemibold from "../Typography/TextSemibold";

const TextCard = ({ text, value, customClasses }) => {
  return (
    <div
      className={`min-w-[150px] flex justify-between items-center flex-col max-w-[230px] px-5 py-4 text-center bg-white rounded-md ${customClasses}`}
    >
      <TextSemibold text={text} customclasses={"!text-[--gray] !text-[15px]"} />
      <TextSemibold
        text={value}
        customclasses={"!text-[--dark-blue] !text-[18px] font-[500] mt-2"}
      />
    </div>
  );
};

export default TextCard;
