import React, { useState } from "react";
import { GiConfirmed } from "react-icons/gi";
import { MdOutlineCancel } from "react-icons/md";
import { useDispatch } from "react-redux";
import { deleteVendor } from "../../Redux/Actions";
import ButtonLoading from "../Buttons/ButtonLoading";

const DeleteVendorModal = ({ handleClose, data }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  function handleDeleteVendor() {
    setBtnLoading(true);
    dispatch(deleteVendor(data)).then(() => {
      setBtnLoading(false);
      handleClose();
    });
  }

  return (
    <section className="w-screen h-screen flex justify-center items-center bgModal z-[100] fixed top-0 left-0">
      <div className="rounded-lg w-[30%] bg-white">
        <div className="w-full py-3 border-b px-3 text-center">
          <h1 className="font-[600] text-[18px]">Confirm Deletion</h1>
        </div>
        <div className="flex justify-start items-center flex-col py-2">
          <p className="px-8 mt-3 text-[18px]">
            Are you sure you want to delete this vendor?
          </p>
          <div className="flex justify-center mt-5 items-center gap-8 w-full py-3">
            {btnLoading ? (
              <ButtonLoading customClasses="!w-1/2" />
            ) : (
              <>
                <button
                  onClick={handleDeleteVendor}
                  className="px-3 py-1 font-[600] flex justify-start items-center border border-[--blue-light] text-[--blue-light] transition-all ease-in-out duration-300 hover:bg-[--blue-light] hover:text-black"
                >
                  <GiConfirmed className="mr-2 text-[18px]" />
                  Yes
                </button>
                <button
                  onClick={handleClose}
                  className="px-3 py-1 font-[600] flex justify-start items-center border border-red-600 text-red-600 transition-all ease-in-out duration-300 hover:bg-red-600 hover:text-white"
                >
                  <MdOutlineCancel className="mr-2 text-[20px]" />
                  No
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeleteVendorModal;
