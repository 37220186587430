import React from "react";
import SideBar from "../Components/SideBar";
import { Outlet, useLocation } from "react-router-dom";

const DashboardLayout = () => {
  const location = useLocation();
  localStorage.setItem("nesmasPath", JSON.stringify(location.pathname));
  return (
    <div className="flex justify-between items-stretch h-full">
      <div >
        <SideBar />
      </div>
      <div className="w-[100%]  md:w-[75%] min-h-[100vh] px-6 py-6 relative">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
