import React from "react";
import logo from "../../Assets/images/logo.png";
import { sidebarData } from "../../Assets/data";
import { Link, useLocation } from "react-router-dom";
import ToogleSidebar from './ToggleSidebar'

const SideBar = () => {
  const location = useLocation();
  return (
    <>
      <div className="w-[100%] h-full hidden md:flex p-3 bg-white min-w-[150px]">
        <div className="w-full">
          <div className="w-[90%] flex items-center md:justify-center mx-auto h-[10vh]">
            <img className="w-full  lg:w-[60%] object-cover" src={logo} alt="NesMas logo" />
          </div>
          <div className="w-full list-none mt-2 px-2">
            {sidebarData.map((navitem, index) => {
              return (
                <li
                  key={index}
                  className={`${location.pathname === navitem.url
                      ? "active_nav_item"
                      : "inactive_nav_item"
                    } transition-all ease-in-out duration-500 mx-auto rounded-sm relative`}
                >
                  <Link
                    to={navitem.url}
                    className=" flex flex-col md:flex-row justify-start items-center pl-0 md:pl-2 lg:pl-5 py-2 mt-2"
                  >
                    <span className="text-2xl">{navitem.icon}</span>
                    <span className="pt-1 text-[14px] font-[500] md:text-[17px] md:ml-3">
                      {navitem.label}
                    </span>
                  </Link>
                </li>
              );
            })}
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div className="md:hidden">
        <ToogleSidebar />
      </div>
    </>
  );
};

export default SideBar;
