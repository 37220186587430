import React, { useState } from "react";
import Profile from "../../Assets/images/profile.jpg";
import TextBolder from "../Typography/TextBolder";
import TextSemibold from "../Typography/TextSemibold";
import { TbDotsVertical } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/Actions";

const Avatar = ({ imageRef, name, role }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(logout());
  }

  function handleDropDown() {
    setIsOpen(!isOpen);
  }
  return (
    <div
      onClick={handleDropDown}
      className=" flex justify-start items-center relative cursor-pointer"
    >
      <div className="w-[50px] h-[50px] hidden md:flex rounded-full relative">
        <img
          className="w-full h-full rounded-full"
          src={imageRef}
          alt="user profile"
        />
      </div>
      <div className="flex justify-center items-start flex-col ml-3">
        <TextBolder customclasses="!text-[10px] sm:!text-[15px] md:!text-[18px] lg:!text-[22px] xl:!text-[25px]" text={name} />
        <TextSemibold
          customclasses="!text-[--gray-dark] capitalize !text-[11px] md:!text-[13px]"
          text={role}
        />
      </div>
      <TbDotsVertical className="text-2xl ml-2 cursor-pointer mr-5" />
      {isOpen ? (
        <div className="bg-white rounded-lg px-3 py- profile-dropdown w-[200px]  relative z-10">
          {/* <Link className="text-[16px] font-[500]  border-b py-2 w-full block">
            Setting
          </Link>
          <Link className="text-[16px] font-[500]  border-b py-2 w-full block">
            New Page
          </Link> */}
          <Link
            onClick={handleLogout}
            className="text-red-600 py-2 text-[16px] font-[500]  block"
          >
            Sign Out
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Avatar;
