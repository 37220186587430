import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.auth);
  const location = useLocation();
  if (user.auth) {
    return children;
  }
  return <Navigate to="/signin" state={{ from: location }} replace/>;
};
