const initialState = {
  isInvoked: false,
  data: [],
  pagesCount: 0,
  currentPage: 0,
  serachData:[]
};

const vendorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_VENDORS": {
      return {
        ...state,
        isInvoked: true,
        pagesCount: payload.meta.totalPages,
        currentPage: payload.meta.currentPage,
        data: payload.venders?.length ? payload.venders : [],
      };
    }
    case "DELETE_VENDOR":
      const updatedVendors = state.data.filter((vendor) => {
        return vendor._id !== payload;
      });
      return {
        ...state,
        data: updatedVendors,
      };
    case "CHANGE_STATUS":
      const updatedVendorsAfterStatusChange = state.data.map((vendor) =>
        vendor._id === payload
          ? { ...vendor, isActive: !vendor.isActive }
          : vendor
      );
      return {
        ...state,
        data: updatedVendorsAfterStatusChange,
      };
    case "SERACH_VENDORS":
      return{
        ...state,
        serachData:payload.data
      }  

    default:
      return state;
  }
};

export default vendorReducer;
