import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../Layout/DashboardLayout";
import Dashboard from "../Pages/Dashboard";
import Users from "../Pages/Users";
import Notification from "../Pages/Notification";
import SignIn from "../Pages/Auth/SignIn";
import { PrivateRoute } from "./PrivateRoute";
import { RedirectRoute } from "./RedirectRoute";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import Profile from "../Pages/Profile";
const storedRoute = sessionStorage.getItem("currentRoute");

export default createBrowserRouter(
  [
    {
      path: "/signin",
      element: (
        <RedirectRoute>
          <SignIn />
        </RedirectRoute>
      ),
    },
    {
      path: "/forget_password",
      element: (
        <RedirectRoute>
          <ForgetPassword />
        </RedirectRoute>
      ),
    },
    {
      path: "",
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "",
          element: <Dashboard />,
        },
        {
          path: "notifications",
          element: <Notification />,
        },
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
      ],
    },
  ],
  storedRoute || "/"
);
