import React, { useRef, useState } from "react";
import TopBar from "../Components/Header/TopBar";
import { useDispatch, useSelector } from "react-redux";
import InputSmall from "../Components/Input/InputSmall";
import ButtonLoading from "../Components/Buttons/ButtonLoading";
import Button from "../Components/Buttons/Button";
import { uploadImage, updateProfileInfo } from "../Redux/Actions";

function Profile() {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const [file, setFile] = useState(undefined);
  const userData = useSelector((state) => state.auth.userData);
  const [changeNameIs, setChangeNameIs] = useState(
    userData ? userData.name : ""
  );
  const loading = useSelector((state) => state.loader.loading);
  {
    /*  */
  }
  const [error, setError] = useState(null);
  const [isUpdate, setIsupdate] = useState(false);

  // on change handlers
  const changeName = (e) => {
    setIsupdate(true);
    setChangeNameIs(e.target.value);
  };
  // console.log(file)
  // console.log(changeNameIs)

  const updateProfile = (e) => {
    if (isUpdate) {
      if (!changeNameIs) {
        alert(" Name cannot be empty");
      } else {
        if(!file){
          dispatch(updateProfileInfo(changeNameIs, userData.profileImg));
        }else{
          dispatch(uploadImage(file)).then((res) => {
            dispatch(updateProfileInfo(changeNameIs, res));
          });
        }
      }
    } else {
      alert("Nothing updated");
    }
  };

  return (
    <div>
      <TopBar isDashboard={false} />
      <div className="mt-3 mx-auto w-full">
        <div className="flex flex-col xsm:w-[100%] sm:w-[70%] md:w-[80%] lg:w-[50%]  mx-auto justify-center p-7 bg-[--gray-bg] drop-shadow-md rounded-xl">
          <input
            onChange={(e) => {
              setIsupdate(true);
              setFile(e.target.files[0]);
            }}
            type="file"
            ref={fileRef}
            hidden
            accept="image/*"
          />
          <img
            onClick={() => fileRef.current.click()}
            src={!file ? `${userData.profileImg}` : URL.createObjectURL(file)}
            alt="profileimage"
            className="w-16 h-16 md:w-20 md:h-20 mx-auto rounded-full aspect-square"
          />
          <div className="space-y-4 text-center divide-y">
            <div className="my-2 space-y-1">
              <h2 className="text-[16px] font-semibold sm:text-[20px]">
                {userData.name}
              </h2>
              <p className="px-5 text-xs sm:text-[16px] text-gray-500 ">
                Admin
              </p>
              <div className="py-1 sm:py-5 px-1 sm:px-4">
                <InputSmall
                  type="text"
                  placeholder={"enterName"}
                  value={
                    changeNameIs === undefined ? userData.name : changeNameIs
                  }
                  onChange={changeName}
                />
                <div className="my-3">
                  {!loading ? (
                    <Button
                      type={"submit"}
                      value="Update"
                      customClass="!py-2"
                      onClick={updateProfile}
                    />
                  ) : (
                    <ButtonLoading />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
