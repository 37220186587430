import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const RedirectRoute = ({ children }) => {
  const user = useSelector((state) => state.auth);
  const location = JSON.parse(localStorage.getItem("nesmasPath"));
  if (user.auth) {
    return <Navigate to={location || "/"} />;

  }
  return children;
};
