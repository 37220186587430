import React from "react";

const CardWrapper = ({ customClassess, children }) => {
  return (
    <div
      className={`w-full flex  justify-start items-center flex-wrap container mx-auto  gap-8 py-5 ${customClassess}`}
    >
      {children}
    </div>
  );
};

export default CardWrapper;
