import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/images/logo.png";
import InputWrapper from "../../Components/Input/InputWrapper";
import InputSmall from "../../Components/Input/InputSmall";
import InputPassword from "../../Components/Input/InputPassword";
import { useFormik } from "formik";
import { loginSchema } from "../../Schema";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Redux/Actions";
import ButtonLoading from "../../Components/Buttons/ButtonLoading";
const initialState = {
  email: "",
  password: "",
};

const SignIn = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loader.loading);

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: loginSchema,
      onSubmit: (values) => {
        dispatch(login(values));
      },
    });
  return (
    <div className="w-screen min-h-screen flex justify-center items-center flex-col pt-4 pb-8 px-0 sm:px-4">
      <div className="w-full py-4 flex justify-center items-center flex-col">
        <div className="w-[100px] h-[40px] sm:w-[110px] sm:h-[40px] md:w-[130px] md:h-[50px]">
          <img className="w-full h-full" src={logo} alt="NesMas logo" />
        </div>
      </div>
      <div className="w-full sm:w-[90%] md:w-[70%] bg-white rounded-3xl sm:rounded-lg p-4 px-8 ">
        <div className="w-full">
          <div className="w-full py-2">
            <h1 className="text-[--blue] poppin-700 text-[22px] sm:text-[26px] md:text-[30px]">
              Sign In
            </h1>
          </div>
          <div>
            <p className="poppin-500 text-[14px] sm:text-[16px]">
              Enter the required information to continue.
            </p>
          </div>

          <div className="w-full md:w-[80%] mx-auto pt-6 pb-2 px-2 md:px-4">
            <InputWrapper>
              <InputSmall
                label="Email"
                name="email"
                type="email"
                placeholder="Enter your email"
                value={values.email}
                touch={touched.email}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.email}
              />
            </InputWrapper>
            <InputWrapper customclasses={"!mt-6"}>
              <InputPassword
                label="Password"
                name="password"
                placeholder="Enter Password"
                value={values.password}
                touch={touched.password}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.password}
              />
            </InputWrapper>
          </div>
          <div className="px-2 md:px-4 w-full md:w-[80%] mx-auto pt-2 pb-5 flex justify-between items-center">
            <Link
              to="/forget_password"
              className="poppin-500 text-[--dark-blue] text-[12px] sm:text-[14px]"
            >
              Forget Password ?
            </Link>
          </div>
          <div className="w-full text-center">
            {loading ? (
              <ButtonLoading customClasses="!w-1/2 !mx-auto !rounded-full" />
            ) : (
              <button
                onClick={handleSubmit}
                type="submit"
                className="px-6 w-1/2 py-3 bg-[--dark-blue] border border-[--dark-blue] text-white text-[16px] rounded-full poppin-500 transition-all ease-in-out duration-300 hover:bg-white hover:text-[--dark-blue]"
              >
                Sign In
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
