import React from "react";

const TextBold = ({ customclasses, text }) => {
  return (
    <h3
      className={`text-[18px] font-[600] text-black capitalize ${customclasses}`}
    >
      {text}
    </h3>
  );
};

export default TextBold;
