import React, { useEffect, useState } from "react";
import { CgSearch } from "react-icons/cg";
import { CiMail } from "react-icons/ci";
import { IoIosNotificationsOutline } from "react-icons/io";
import Avatar from "../Components/Avatar";
import TextBold from "../Components/Typography/TextBold";
import CardWrapper from "../Components/Wrappers/CardWrapper";
import {
    lineChartData,
    months,
    quarters,
    userData,
    years,
} from "../Assets/data";
import TextCard from "../Components/Cards/TextCard";
import Linechart from "../Components/Charts/Linechart";
import Dropdown from "../Components/Inputs/Dropdown";
// import Modal from "../Components/Modal/Modal";
// import { GiConfirmed } from "react-icons/gi";
// import { MdOutlineCancel } from "react-icons/md";
import TopBar from "../Components/Header/TopBar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Components/Loading/Loader";
import { changeVendorStatus, getDashboardData } from "../Redux/Actions";
import DeleteVendorModal from "../Components/Modal/DeleteVendorModal";
import moment from "moment";

function cachFormatter(amount) {
    if (amount) {
        if (amount >= 1000000000) {
            return (amount / 1000000000).toFixed(1) + 'B';
        } else if (amount >= 1000000) {
            return (amount / 1000000).toFixed(1) + 'M';
        } else if (amount >= 1000) {
            return (amount / 1000).toFixed(1) + 'K';
        } else {
            return amount.toFixed(0);
        }
    }
    return 0;
}

let Once = true;

const Dashboard = () => {
    const [modal, setModal] = useState(false);
    const [deletedVendorData, setDeletedVendorData] = useState("");
    const loading = useSelector((state) => state.loader.loading);
    const { isInvoked, data } = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();

    function handleModal() {
        setModal(!modal);
    }

    function deleteVendor(data) {
      setDeletedVendorData(data.id);
      setModal(true);
    }
    
  function handleStatuschange(vendorId) {
    dispatch(changeVendorStatus(vendorId));
  }


    useEffect(() => {
        if (Once) {
            Once = false;
            if (!isInvoked && !data.length) {
                dispatch(getDashboardData());
            }
        }
    }, []);

    return (
    <>
        {modal ? (
        <DeleteVendorModal handleClose={handleModal} data={deletedVendorData} />
            ) : null}
      <TopBar isDashboard={false} headerText="Overview"/>
      {/* <div className="w-full flex justify-between items-center py-3">
                <div className="bg-white w-[60%] flex justify-start items-stretch">
                  <input
                    type="text"
                    placeholder="Search"
                    className="py-3 w-[93%] px-3 outline-none"
                  />
                  <div className="flex justify-center items-center px-2 ">
                    <CgSearch className="w-[25px] h-[25px] text-slate-400" />
                  </div>
                </div>

                <div className="flex justify-between items-center w-[35%] gap-4">
                  <div>
                    <CiMail className="text-3xl" />
                  </div>
                  <div>
                    <IoIosNotificationsOutline className="text-3xl" />
                  </div>
                  <Avatar name="John Doe" role="Admin" />
                </div>
              </div> */}

    {loading ? (
        <Loader />
        ) : (
        <>
            <div className="flex flex-col">
            {/* <TextBold text="Overview" customclasses="!text-[18px] md:!text-[30px]" /> */}
            <CardWrapper >
            <TextCard text="Subscribed" value={cachFormatter(data?.subscribedVender)} />
              <TextCard text="Unsubscribed" value={cachFormatter(data?.unSubscribedVenders)} />
              <TextCard
                text="Sum of Funds Subscribed"
            value={cachFormatter(data?.sumOfFunds)}
            />
              <TextCard text="Share to Friends" value={cachFormatter(data?.share)} />
              <TextCard text="Total Vendors" value={cachFormatter(data?.allVenders)} />
              <TextCard text="New Vendors" value={cachFormatter(data?.newVenders)} />
            </CardWrapper>
          </div>
          <div className="w-full">
            <div className="w-full py-4 px-5 linearBlue">
            <TextBold text="Sum Of Funds Subscribed " />
            </div>
            <Linechart data={data?.fundsGraph} />
          </div>
          {
            data?.top50Venders?.length  ? <>
                      <div className="flex justify-between items-center mt-8">
                            <h4 className="text-[20px] font-[500]">Top Active Vendors</h4>
                            {/* <div className="flex justify-center items-center gap-4">
                              <button className="bg-[--gray-bg]  font-[500] text-[16px] px-5 py-1">
                                Download CSV
                              </button>
                              <Dropdown options={years} />
                              <Dropdown options={months} />
                              <Dropdown options={quarters} />
                            </div> */}
                          </div>
                           <div className="mt-7 mb-3 dashboardTableWrapper ">
                            <table className="w-full min-w-[850px] border-collapse !overflow-x-auto bg-white">
                              <thead className="border-b-[15px] border-[--gray-light]">
                                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                                  S/N
                                </th>
                                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                                  Name
                                </th>
                                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                                  Email
                                </th>
                                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                                  Phone
                                </th>
                                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                                  Creation Date
                                </th>
                                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                                  Suspand Option
                                </th>
                                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                                  Subscription Status
                                </th>
                                <th className="text-[--gray] font-[600] text-[16px] py-5 border px-2">
                                  Option
                                </th>
                              </thead>
                              <tbody>
                  {data?.top50Venders.map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td className="border text-center px-2 text-sm py-2">
                          {index + 1}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          {rowData.name}
                        </td>
                        <td
                          onClick={(e) => {
                            if (e.target.classList.contains("max-w-[160px]")) {
                              e.target.classList.remove("max-w-[160px]");
                              e.target.classList.add("max-w-max");
                            } else {
                              e.target.classList.remove("max-w-max");
                              e.target.classList.add("max-w-[160px]");
                            }
                          }}
                          className="cursor-pointer border text-center px-2 text-sm py-2 max-w-[160px] text-ellipsis overflow-hidden"
                        >
                          {rowData.email}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          {rowData.phoneNumber}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          {moment(rowData.dateCreated).format("YYYY-MM-DD")}
                        </td>
                        <td className="text-center px-1 text-sm py-2 capitalize border">
                          <select
                            name="status"
                            value={rowData.isActive}
                            onChange={() => {
                              handleStatuschange(rowData._id);
                            }}
                            className={`px-2 py-1 outline-none ${
                              rowData.isActive
                                ? "bg-green-200 text-green-600 border border-green-600"
                                : "bg-red-200 text-red-600 border border-red-600"
                            }`}
                          >
                            <option
                              className="bg-white text-black text-[14px]"
                              value={true}
                            >
                              Active
                            </option>
                            <option
                              className="bg-white text-black text-[14px]"
                              value={false}
                            >
                              InActive
                            </option>
                          </select>
                        </td>
                        <td className="px-3 text-center capitalize border">
                          {rowData.isSubscribe ? (
                            <button className="bg-green-200 text-green-600 px-1 py-1  text-sm border border-green-600">
                              Subscribed
                            </button>
                          ) : (
                            <button className="bg-red-200 text-red-600 border border-red-600 px-2 py-1  text-sm">
                              Un-Subscribed
                            </button>
                          )}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          <button
                            onClick={() => {
                              deleteVendor({ id: rowData._id });
                            }}
                            className="border border-gray-600 bg-gray-300 text-black px-3 py-1 transition-all ease-in-out duration-300 hover:bg-gray-800 hover:text-white"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                            </table>
                          </div>
            
            </>
            : null
          }

        </>
        )}
    </>
);
};

export default Dashboard;