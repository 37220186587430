import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loaderReducer from "./loaderReducer";
import dashboardReducer from "./dashboardReducer";
import vendorReducer from "./vendorReducer";

export default combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  dashboard: dashboardReducer,
  vendor: vendorReducer,
});
