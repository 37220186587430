import React from "react";
import Avatar from "../Avatar";
import { IoIosNotificationsOutline } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import { CgSearch } from "react-icons/cg";
import TextBold from "../Typography/TextBold";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const TopBar = ({ isDashboard, headerText }) => {
  const userData = useSelector((state) => state.auth.userData);

  return (
    <div className="w-full flex justify-between items-center py-3">
      {isDashboard ? (
        <div className="bg-white flex justify-start items-stretch w-[50%]">
          <input
            type="text"
            placeholder="Search"
            className="py-3 w-[93%] px-3 outline-none"
          />
          <div className="flex justify-center items-center px-2 ">
            <CgSearch className="w-[25px] h-[25px] text-slate-400 cursor-pointer hover:text-slate-500" />
          </div>
        </div>
      ) : (
        <div className="w-[50%]">
          <TextBold text={headerText} customclasses="!text-[20px] md:!text-[30px]" />
        </div>
      )}

      <div className="flex justify-between items-center  gap-4">
        {/* <div>
          <CiMail className="text-3xl" />
        </div> */}
        {/* <Link to="/notifications">
          <IoIosNotificationsOutline className="text-3xl" />
        </Link> */}
        <Link to={"/profile"}>
        <Avatar
          imageRef={userData?.profileImg}
          name={userData?.name}
          role="Admin"
        />
        </Link>
      </div>
    </div>
  );
};

export default TopBar;
