import React, { useEffect, useState } from "react";
import { CgSearch } from "react-icons/cg";
import TextSemibold from "../Components/Typography/TextSemibold";
import Dropdown from "../Components/Inputs/Dropdown";
import { months, quarters, years } from "../Assets/data";
import TopBar from "../Components/Header/TopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  changeVendorStatus,
  fetchAllVendors,
  getVendorsData,
  searchSpecificVendor,
  searchVendor,
} from "../Redux/Actions";
import Loader from "../Components/Loading/Loader";
import DeleteVendorModal from "../Components/Modal/DeleteVendorModal";
import moment from "moment";
import TextBold from "../Components/Typography/TextBold";
import { CiMail } from "react-icons/ci";
import { IoIosNotificationsOutline } from "react-icons/io";
import Avatar from "../Components/Avatar";
import { IoClose } from "react-icons/io5";
let Once = true;

const Users = () => {
  const [modal, setModal] = useState(false);
  const [deletedVendorData, setDeletedVendorData] = useState("");
  const dispatch = useDispatch();
  const { isInvoked, data, pagesCount, currentPage,serachData } = useSelector(
    (state) => state.vendor
  );
  const loading = useSelector((state) => state.loader.loading);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(null);
  const [quarter, setQuarter] = useState(null);
  const [searchItem,setSearchItem] = useState(null);
  const [isSearched,setIsSearched] = useState(false);

  function deleteVendor(data) {
    setDeletedVendorData(data.id);
    setModal(true);
  }

  function closeModal() {
    setDeletedVendorData("");
    setModal(false);
  }

  function onMonthChange(e) {
    dispatch(
      getVendorsData(`page=${currentPage}&year=${year}&month=${e.target.value}`)
    );
    setMonth(e.target.value);
  }

  function onQuarterChange(e) {
    dispatch(
      getVendorsData(
        `page=${currentPage}&year=${year}&quarter=${e.target.value}`
      )
    );
    setQuarter(e.target.value);
  }

  function onYearChange(e) {
    dispatch(getVendorsData(`page=1&year=${e.target.value}`));
    setYear(e.target.value);
  }

  function onNextPage() {
    dispatch(getVendorsData(`page=${currentPage + 1}&year=${year}`));
  }
  function onBackPage() {
    dispatch(getVendorsData(`page=${currentPage - 1}&year=${year}`));
  }

  function handleStatuschange(vendorId) {
    dispatch(changeVendorStatus(vendorId));
  }

  function handleDownloadCSV() {
    fetchAllVendors()
      .then((res) => {
        if (res?.venders?.length) {
          // Generate CSV content
          const csvContent =
            "id,Vendor Name,Email,Phone,Joined Date,Language,isActive,isSubscribed,Profile Picture\n" +
            res.venders
              .map(
                (vendor) =>
                  `${vendor._id},${vendor.name},${vendor.email},${vendor.phoneNumber},${vendor.dateCreated},${vendor.language},${vendor.isActive},${vendor.isSubscribe},${vendor.venderProfileImage}`
              )
              .join("\n");

          // Create a Blob with the CSV content
          const blob = new Blob([csvContent], { type: "text/csv" });

          // Create a download link and trigger click
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "vendor_data.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          alert("No vendor found");
        }
      })
      .catch((err) => {
        console.log("error ===>", err);
      });
    // if (data.length === 0) {
    //   console.error("No data available for CSV download");
    //   return;
    // }
  }

  function searchVendor(e){
    e.preventDefault();
    if(!searchItem){
      return;
    }else{
      setIsSearched(true);
      dispatch(searchSpecificVendor({searchTerm:searchItem}));
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked && !data.length) {
        dispatch(getVendorsData(`page=1&year=${year}`));
      }
    }
  }, []);

  return (
    <>
      {modal ? (
        <DeleteVendorModal handleClose={closeModal} data={deletedVendorData} />
      ) : null}
      <TopBar isDashboard={false} headerText={"Vendors"} />
      <form onSubmit={searchVendor} className="bg-white w-[60%] flex justify-start items-stretch mb-2">
            <input
              type="text"
              name="searchItem"
              placeholder="Search"
              value={searchItem}
              onChange={(e)=>{
                if(e.target.value){
                  setSearchItem(e.target.value);
                }else{
                  setIsSearched(false);
                  setSearchItem(e.target.value);

                }
              }}
              className="py-2 w-[93%] px-3 outline-none rounded-sm sm:py-3"
            />
            <div className={`${isSearched ? "bg-red-600 ":"hover:bg-slate-100"} flex justify-center items-center px-2 cursor-pointer transition-all ease-in-out duration-500 `}>
              {
                isSearched ? 
<IoClose onClick={()=>{setIsSearched(false)}} className="w-[25px] h-[25px] text-white" />
                :<button type="submit"><CgSearch className="w-[25px] h-[25px] text-slate-400  hover:text-slate-500" /></button>
              }
              
            </div>
          </form>
          {
            isSearched ? null : <div className="flex justify-end items-center w-full">
            <button
              onClick={handleDownloadCSV}
              className="bg-[--gray-bg]  font-[500] px-5 py-2 text-[16px]"
            >
              Download CSV
            </button>
          </div>
          }
          
{/* 
      <div className="w-full flex justify-between items-center py-3">
        <div className="w-[55%]">
          <TextBold text="Users" customclasses="!text-[30px]" />
        </div>
        <div className="flex justify-between items-center w-[35%] gap-4">
          <div>
            <CiMail className="text-3xl" />
          </div>
          <div>
            <IoIosNotificationsOutline className="text-3xl" />
          </div>
          <Avatar name="John Doe" role="Admin" />
        </div>
      </div> */}
      {loading ? (
        <Loader />
      ) : (
        isSearched  ?
        <div className="mt-7 mb-3 w-[77vw] sm:w-[100%] overflow-x-auto ">
              <table className="w-full min-w-[850px] border-collapse !overflow-x-auto bg-white">
                <thead className="border-b-[15px] border-[--gray-light]">
                  <tr>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    S/N
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Name
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Email
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Phone
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Creation Date
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Suspand Option
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Subscription Status
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 border px-2">
                    Option
                  </th>
                  </tr>
                </thead>
                <tbody>
                  {serachData.map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td className="border text-center px-2 text-sm py-2">
                          {index + 1}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          {rowData.name}
                        </td>
                        <td
                          onClick={(e) => {
                            if (e.target.classList.contains("max-w-[160px]")) {
                              e.target.classList.remove("max-w-[160px]");
                              e.target.classList.add("max-w-max");
                            } else {
                              e.target.classList.remove("max-w-max");
                              e.target.classList.add("max-w-[160px]");
                            }
                          }}
                          className="cursor-pointer border text-center px-2 text-sm py-2 max-w-[160px] text-ellipsis overflow-hidden"
                        >
                          {rowData.email}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          {rowData.phoneNumber}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          {moment(rowData.dateCreated).format("YYYY-MM-DD")}
                        </td>
                        <td className="text-center px-1 text-sm py-2 capitalize border">
                          <select
                            name="status"
                            value={rowData.isActive}
                            onChange={() => {
                              handleStatuschange(rowData._id);
                            }}
                            className={`px-2 py-1 outline-none ${
                              rowData.isActive
                                ? "bg-green-200 text-green-600 border border-green-600"
                                : "bg-red-200 text-red-600 border border-red-600"
                            }`}
                          >
                            <option
                              className="bg-white text-black text-[14px]"
                              value={true}
                            >
                              Active
                            </option>
                            <option
                              className="bg-white text-black text-[14px]"
                              value={false}
                            >
                              InActive
                            </option>
                          </select>
                        </td>
                        <td className="px-3 text-center capitalize border">
                          {rowData.isSubscribe ? (
                            <button className="bg-green-200 text-green-600 px-1 py-1  text-sm border border-green-600">
                              Subscribed
                            </button>
                          ) : (
                            <button className="bg-red-200 text-red-600 border border-red-600 px-2 py-1  text-sm">
                              Un-Subscribed
                            </button>
                          )}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          <button
                            onClick={() => {
                              deleteVendor({ id: rowData._id });
                            }}
                            className="border border-gray-600 bg-gray-300 text-black px-3 py-1 transition-all ease-in-out duration-300 hover:bg-gray-800 hover:text-white"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
        : <>
          <div className="w-[76vw]  sm:w-full flex flex-col justify-between  sm:flex-row sm:items-center mt-5">
            <TextSemibold text="Vendors Details" customclasses="!text-[16px] sm:mb-5  md:!text-[21px]" />
            <div className="flex justify-end sm:justify-between items-center gap-2 md:gap-3 mt-3">
              <select
                name="year"
                id="year"
                value={year}
                onChange={onYearChange}
                className="px-1 py-1 md:py-2 md:px-2  border border-[--blue-light] text-[--blue-light] outline-none capitalize"
              >
                {years.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item.value}
                      className="text-black"
                    >
                      {item.label}
                    </option>
                  );
                })}
              </select>

              <select
                name="month"
                id="month"
                value={month}
                onChange={onMonthChange}
                className=" py-1 sm:px-2 md:py-2  border border-[--blue-light] text-[--blue-light] outline-none capitalize"
              >
                {months.map((item, index) => {
                  if (index === 0) {
                    return (
                      <option selected disabled value={item.value}>
                        {item.label}
                      </option>
                    );
                  } else {
                    return (
                      <option value={item.value} className="text-black">
                        {item.label}
                      </option>
                    );
                  }
                })}
              </select>
              <select
                name="quarter"
                id="quarter"
                value={quarter}
                onChange={onQuarterChange}
                className="py-1 sm:px-2 md:py-2 border border-[--blue-light] text-[--blue-light] outline-none capitalize"
              >
                {quarters.map((item, index) => {
                  if (index === 0) {
                    return (
                      <option selected disabled value={item.value}>
                        {item.label}
                      </option>
                    );
                  } else {
                    return (
                      <option value={item.value} className="text-black">
                        {item.label}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
          </div>
          {data.length ? (
            <>
            <div className="mt-7 mb-3 w-[77vw] sm:w-[100%] overflow-x-auto ">
              <table className="w-full min-w-[850px] border-collapse !overflow-x-auto bg-white">
                <thead className="border-b-[15px] border-[--gray-light]">
                  <tr>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    S/N
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Name
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Email
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Phone
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Creation Date
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Suspand Option
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                    Subscription Status
                  </th>
                  <th className="text-[--gray] font-[600] text-[16px] py-5 border px-2">
                    Option
                  </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td className="border text-center px-2 text-sm py-2">
                          {index + 1}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          {rowData.name}
                        </td>
                        <td
                          onClick={(e) => {
                            if (e.target.classList.contains("max-w-[160px]")) {
                              e.target.classList.remove("max-w-[160px]");
                              e.target.classList.add("max-w-max");
                            } else {
                              e.target.classList.remove("max-w-max");
                              e.target.classList.add("max-w-[160px]");
                            }
                          }}
                          className="cursor-pointer border text-center px-2 text-sm py-2 max-w-[160px] text-ellipsis overflow-hidden"
                        >
                          {rowData.email}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          {rowData.phoneNumber}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          {moment(rowData.dateCreated).format("YYYY-MM-DD")}
                        </td>
                        <td className="text-center px-1 text-sm py-2 capitalize border">
                          <select
                            name="status"
                            value={rowData.isActive}
                            onChange={() => {
                              handleStatuschange(rowData._id);
                            }}
                            className={`px-2 py-1 outline-none ${
                              rowData.isActive
                                ? "bg-green-200 text-green-600 border border-green-600"
                                : "bg-red-200 text-red-600 border border-red-600"
                            }`}
                          >
                            <option
                              className="bg-white text-black text-[14px]"
                              value={true}
                            >
                              Active
                            </option>
                            <option
                              className="bg-white text-black text-[14px]"
                              value={false}
                            >
                              InActive
                            </option>
                          </select>
                        </td>
                        <td className="px-3 text-center capitalize border">
                          {rowData.isSubscribe ? (
                            <button className="bg-green-200 text-green-600 px-1 py-1  text-sm border border-green-600">
                              Subscribed
                            </button>
                          ) : (
                            <button className="bg-red-200 text-red-600 border border-red-600 px-2 py-1  text-sm">
                              Un-Subscribed
                            </button>
                          )}
                        </td>
                        <td className="border text-center px-2 text-sm py-2 capitalize">
                          <button
                            onClick={() => {
                              deleteVendor({ id: rowData._id });
                            }}
                            className="border border-gray-600 bg-gray-300 text-black px-3 py-1 transition-all ease-in-out duration-300 hover:bg-gray-800 hover:text-white"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="w-full mt-3 ml-3">
                <TextSemibold text={`Total Pages : ${pagesCount}`}/>
              </div>
            </>
          ) : (
            <p>No vendor found</p>
          )}

          <div className="w-full gap-6 flex justify-center items-center">
            {currentPage > 1 ? (
              <button
                onClick={onBackPage}
                className="text-[18px] py-1 px-5 border border-[--dark-blue] text-[--dark-blue]"
              >
                Previous
              </button>
            ) : null}

            {currentPage < pagesCount ? (
              <button
                onClick={onNextPage}
                className="text-[18px] py-1 px-5 border border-[--dark-blue] text-[--dark-blue]"
              >
                Next
              </button>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default Users;
