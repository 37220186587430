import React from "react";
import { useFormik } from "formik";
import { notificationSchema } from "../Schema";
import ButtonLoading from "../Components/Buttons/ButtonLoading";
import TopBar from "../Components/Header/TopBar";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../Redux/Actions";
const initialState = {
  text: "",
  description: "",
};

const Notification = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loader.loading);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: notificationSchema,
      onSubmit: (values, action) => {
        dispatch(addNotification(values)).then(() => {
          action.resetForm();
        });
      },
    });
  return (
    <>
      <TopBar isDashboard={false} headerText={"Notification"}  />
      {/* <div className="w-full flex justify-between items-center py-3">
        <div className="w-[55%]">
          <TextBold text="Send Notification" customclasses="!text-[30px]" />
        </div>
        <div className="flex justify-between items-center w-[35%] gap-4">
          <div>
            <CiMail className="text-3xl" />
          </div>
          <div>
            <IoIosNotificationsOutline className="text-3xl" />
          </div>
          <Avatar name="John Doe" role="Admin" />
        </div>
      </div> */}
      <div className="flex justify-start  flex-col">
        <label htmlFor="tittle" className="font-[500] text-[18px] mb-1  md:text-[22px]">
          Title
        </label>
        <input
          type="text"
          id="tittle"
          name="text"
          placeholder="Title here..."
          className="py-2 px-3 text-[14px] bg-white sm:w-full md:text-[18px] w-[75vw]"
          value={values.text}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {errors.text && touched.text ? (
          <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
            {errors.text}
          </span>
        ) : null}
      </div>

      <div className="flex justify-start items-start flex-col mt-4 md:mt-6">
        <label htmlFor="description" className="font-[500] text-[18px] mb-1 md:text-[22px]">
          Description
        </label>
        <textarea
          rows={9}
          type="text"
          name="description"
          id="description"
          placeholder="Description here..."
          className="py-2 px-3 text-[14px] bg-white sm:w-full md:text-[18px] w-[75vw]"
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {errors.description && touched.description ? (
          <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
            {errors.description}
          </span>
        ) : null}
      </div>
      <div className="flex justify-end items-center mt-4 ">
        {loading ? (
          <ButtonLoading customClasses="!w-[150px] !rounded-none" />
        ) : (
          <button
            onClick={handleSubmit}
            className="bg-[--dark-blue]  text-white font-[600] text-[16px] px-5 py-1 "
          >
            Send
          </button>
        )}
      </div>
    </>
  );
};

export default Notification;
