import { LuLayoutDashboard, LuUsers2 } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";
import { PiBellSimple } from "react-icons/pi";

export const sidebarData = [
  {
    label: "Dashboard",
    url: "/",
    icon: <LuLayoutDashboard />,
  },
  {
    label: "Notifications",
    url: "/notifications",
    icon: <PiBellSimple />,
  },
  {
    label: "Vendors",
    url: "/users",
    icon: <LuUsers2 />,
  },
  {
    label: "Profile",
    url: "/profile",
    icon: <CgProfile />,
  },
];

export const lineChartData = [
  {
    month: "Jan",
    value: "20000",
  },
  {
    month: "Feb",
    value: "50000",
  },
  {
    month: "March",
    value: "30000",
  },
  {
    month: "April",
    value: "45000",
  },
  {
    month: "May",
    value: "70000",
  },
  {
    month: "June",
    value: "80000",
  },
  {
    month: "July",
    value: "50000",
  },
  {
    month: "Aug",
    value: "70000",
  },
  {
    month: "Sep",
    value: "80000",
  },
  {
    month: "Oct",
    value: "60000",
  },
  {
    month: "Nov",
    value: "90000",
  },
  {
    month: "Dec",
    value: "50000",
  },
];

export const years = [
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2023",
    value: "2023",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2018",
    value: "2018",
  },
  {
    label: "2017",
    value: "2017",
  },
];

export const months = [
  {
    label: "Month",
    value: null,
  },
  {
    label: "January",
    value: "jan",
  },
  {
    label: "Febuary",
    value: "feb",
  },
  {
    label: "March",
    value: "mar",
  },
  {
    label: "April",
    value: "apr",
  },
  {
    label: "May",
    value: "may",
  },
  {
    label: "June",
    value: "jun",
  },
  {
    label: "July",
    value: "jul",
  },

  {
    label: "August",
    value: "aug",
  },
  {
    label: "September",
    value: "sep",
  },
  {
    label: "October",
    value: "oct",
  },
  {
    label: "November",
    value: "nov",
  },
  {
    label: "December",
    value: "dec",
  },
];

export const quarters = [
  {
    label: "Quarter",
    value: "",
  },
  {
    label: "Quarter 1",
    value: "q1",
  },
  {
    label: "Quarter 2",
    value: "q2",
  },
  {
    label: "Quarter 3",
    value: "q3",
  },
  {
    label: "Quarter 4",
    value: "q4",
  },
];

export const userData = [
  {
    serial_no: 1,
    name: "John Doe",
    email: "john@gmail.com",
    number: "+2347062231621",
    creation_date: "2 Nov,2023",
    suspendedOption: "active",
    subscription: true,
  },
  {
    serial_no: 1,
    name: "John Doe",
    email: "john@gmail.com",
    number: "+2347062231621",
    creation_date: "2 Nov,2023",
    suspendedOption: "inactive",
    subscription: true,
  },
  {
    serial_no: 1,
    name: "John Doe",
    email: "john@gmail.com",
    number: "+2347062231621",
    creation_date: "2 Nov,2023",
    suspendedOption: "active",
    subscription: false,
  },
];
