import React from "react";

const TextBolder = ({ customclasses, text }) => {
  return (
    <h5
      className={`text-[18px] font-[700] text-black capitalize ${customclasses}`}
    >
      {text}
    </h5>
  );
};

export default TextBolder;
