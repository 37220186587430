const initialState = {
  isInvoked: false,
  data: [],
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_DATA":
      return {
        ...state,
        isInvoked: true,
        data: payload.data,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
