import * as Yup from "yup";

const stringValidation = (message = "Field is required") =>
  Yup.string().required(message);

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} `;
};

export const loginSchema = Yup.object({
  email: stringValidation("Email is required").email(),
  password: stringValidation("Password is required"),
});

export const notificationSchema = Yup.object({
  text: stringValidation("Title is required"),
  description: stringValidation("Description is required").min(
    8,
    "Description must be at least 8 characters"
  ),
});

export const emailSchema = Yup.object({
  email: stringValidation("Email is required").email(),
});

export const otpValidation = Yup.object({
  otp: Yup.number().required("OTP is required"),
});

export const passwordSchema = Yup.object({
  newPassword: stringValidation("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase character"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase character"))
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      getCharacterValidationError("special character")
    ),
  confirmPassword: stringValidation("Confirm Password is required").oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});
