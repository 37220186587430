import axios from "axios";
import { successToast, errorToast } from "../../Utils/Toast";
import authReducer from "../Reducers/authReducer";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

export const login = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "LOAD" });
    const response = await api.post("/admin", payload);
    dispatch({ type: "LOGIN", payload: response.data });
    dispatch({ type: "UNLOAD" });
    return;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    if (error.response) {
      if (error.response.status === 400) {
        errorToast("Email or password incorrect");
      } else {
        errorToast("Something went wrong");
      }
    } else {
      errorToast("Something went wrong");
    }
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: "LOAD_MAIN" });
    const response = await api.post("/admin/logout");
    dispatch({ type: "LOGOUT" });
    dispatch({ type: "UNLOAD" });
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
    dispatch({ type: "UNLOAD" });
  }
};

export const emailVerification = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "LOAD" });
    const response = await api.get(`/admin/forgetPassword?email=${payload}`);
    dispatch({ type: "UNLOAD" });
    return response.data;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    if (error?.response?.status === 404) {
      errorToast("No user with this email");
      throw error;
    } else {
      errorToast("Something went wrong");
      throw error;
    }
  }
};

export const otpVerification = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "LOAD" });
    const response = await api.get(
      `/vender/otpValidation?otp=${payload.otp}&encryptOpts=${payload.encryptOpts}`
    );
    dispatch({ type: "UNLOAD" });
    return response.data;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    if (error?.response?.data) {
      errorToast(error.response?.data?.message);
      throw error;
    } else {
      errorToast("Something went wrong");
      throw error;
    }
  }
};

export const updateProfileInfo =
  (name, profileImg ) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOAD" });
      const response = await api.put(`/admin/userProfile`, {
        name,
        profileImg,
      });
      dispatch({ type: "LOGIN", payload: response.data });
      dispatch({ type: "UNLOAD" });
      return response.data;
    } catch (error) {
      dispatch({ type: "UNLOAD" });
      if (error?.response?.data) {
        errorToast(error.response?.data?.message);
        throw error;
      } else {
        errorToast("Something went wrong");
        throw error;
      }
    }
  };

export const uploadImage = (payload) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("file", payload);
    dispatch({ type: "LOAD" });
    const response = await api.post(`/aws/uploadImgAdmin`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    dispatch({ type: "UNLOAD" });
    return response.data.data[0];
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    if (error?.response?.data) {
      errorToast(error.response?.data?.message);
      throw error;
    } else {
      errorToast("Something went wrong");
      throw error;
    }
  }
};

export const setNewpassword = (payload) => async (dispatch) => {
  try {
    console.log("Payload ===", payload);
    dispatch({ type: "LOAD" });
    const response = await api.post("/admin/setPassword", payload);
    dispatch({ type: "UNLOAD" });
    successToast("Your password has been updated");
    return response.data;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    if (error?.response?.data) {
      errorToast(error.response?.data?.message);
      throw error;
    } else {
      errorToast("Something went wrong");
      throw error;
    }
  }
};

export const addNotification = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "LOAD" });
    const response = await api.post("/notification", payload);
    // console.log(response.data.data);
    successToast("Notification posted successfully");
    dispatch({ type: "UNLOAD" });
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
    dispatch({ type: "UNLOAD" });
  }
};

export const getDashboardData = () => async (dispatch) => {
  try {
    dispatch({ type: "LOAD" });
    const response = await api.get("/dashboard");
    dispatch({ type: "FETCH_DATA", payload: response.data });
    dispatch({ type: "UNLOAD" });
    return;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const getVendorsData = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "LOAD" });
    const response = await api.get(`/dashboard/getVenders?limit=10&${payload}`);
    dispatch({ type: "FETCH_VENDORS", payload: response?.data?.data });
    dispatch({ type: "UNLOAD" });
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const fetchAllVendors = async () => {
  try {
    const response = await api.get(`/dashboard/getVenders`);
    return response.data?.data;
  } catch (error) {
    throw new Error("Something went wrong");
  }
};

export const deleteVendor = (payload) => async (dispatch) => {
  try {
    const response = await api.post(
      `/dashboard/venderDelete?venderId=${payload}`
    );
    dispatch({ type: "DELETE_VENDOR", payload: payload });
    successToast("Vendor deleted successfully");
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const changeVendorStatus = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "LOAD_MAIN" });
    const response = await api.post(
      `dashboard/venderActivate?venderId=${payload}`
    );
    dispatch({ type: "CHANGE_STATUS", payload: payload });
    successToast("Vendor Status changed successfully");
    dispatch({ type: "UNLOAD" });

    return;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const searchSpecificVendor = (payload)=>async(dispatch)=>{
  try {
    dispatch({ type: "LOAD" });
    const response = await api.post("dashboard/searchVenders",payload);
    dispatch({type:"SERACH_VENDORS",payload:response.data})
    dispatch({ type: "UNLOAD" });

    return;
  } catch (error) {
    dispatch({ type: "UNLOAD" });

    console.log(error);
    errorToast("Something went wrong");
  }
}

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/refreshToken`,
          {
            withCredentials: true,
          }
        );
        // authReducer({ type: "LOGIN", payload: response.data });
        return api.request(originalRequest);
      } catch (error) {
        authReducer({ type: "LOGOUT" });
      }
    }
    throw error;
  }
);

export const checkLogin = () => async (dispatch) => {
  try {
    dispatch({ type: "LOAD_MAIN" });
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/refreshToken`,
      {
        withCredentials: true,
      }
    );

    dispatch({ type: "LOGIN", payload: response.data });
    dispatch({ type: "UNLOAD" });
  } catch (error) {
    dispatch({ type: "LOGOUT" });
    dispatch({ type: "UNLOAD" });
  }
};
