import React, { useState } from 'react'
import logo from "../../Assets/images/logo.png";
import { sidebarData } from "../../Assets/data";
import { TiThMenu } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";

const ToggleSidebar = () => {
    const location = useLocation();
    const [showBar, setShowBar] = useState(false)
    return (
        <>
            <div className='mt-9 w-12 h-12 flex items-center  justify-center rounded-md'>
                <TiThMenu className='text-xl' onClick={() => setShowBar(!showBar)} />
            </div>
            {
                showBar && (

                    <div className={`w-[60%] h-full md:hidden absolute z-50  p-3 bg-white top-0  min-w-[150px] transition-all duration-200 ${showBar ? "left-0" : "-left-[100%]"}`}>
                        <div className="w-full">
                            <div className={`w-[100vw] flex items-center md:justify-center mx-auto h-[10vh]`}
                            onClick={()=>setShowBar(!showBar)}>
                                <img className="w-[33%]  object-cover" src={logo} alt="NesMas logo" />
                            </div>
                            <div className="w-full list-none mt-2 px-2 ">
                                {sidebarData.map((navitem, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={`${location.pathname === navitem.url
                                                ? "active_nav_item"
                                                : "inactive_nav_item"
                                                } transition-all ease-in-out duration-500 mx-auto rounded-sm relative`}
                                        >
                                            <Link
                                                to={navitem.url}
                                                className=" flex flex-row justify-start items-center pl-5 py-2 mt-5"
                                                onClick={()=>setShowBar(!showBar)}
                                            >
                                                <span className="text-2xl">{navitem.icon}</span>
                                                <span className="font-[500] text-[17px] ml-3">
                                                    {navitem.label}
                                                </span>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )

            }
        </>
    )
}

export default ToggleSidebar