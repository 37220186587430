import React from "react";

const TextSemibold = ({ customclasses, text }) => {
  return (
    <h5
      className={`text-[13px] font-[500] text-black capitalize ${customclasses}`}
    >
      {text}
    </h5>
  );
};

export default TextSemibold;
