import { RouterProvider } from "react-router-dom";
import Routes from "./Routes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Components/Loading/Loader";
import { checkLogin } from "./Redux/Actions";

function App() {
  const dispatch = useDispatch();
  const mainLoader = useSelector((state) => state.loader.mainLoader);

  useEffect(() => {
    dispatch(checkLogin());
  }, []);
  return (
    <>
      {mainLoader ? (
        <section className="w-screen h-screen flex justify-center items-center">
          <Loader />
        </section>
      ) : (
        <RouterProvider router={Routes} />
      )}
    </>
  );
}

export default App;
